import React from 'react';
import './App.css';
import FirstSection from '../molecules/firstSection/index';
import SecondSection from '../molecules/secondSection';
import Header from '../molecules/header';
import ThirdSection from '../molecules/thirdSection';
import Footer from '../molecules/footer';

class App extends React.Component {
  render() {
    return (
        <div className="App">
          <Header />
          <FirstSection />
          <SecondSection />
          <ThirdSection />
          <Footer />
        </div>
    );
  }
}

export default App;
