import React from 'react';
import i18next from 'i18next';

const LinkLng = ({item, isLast, languageMap, className}) => {
	return (
			<a className={className} key={item} onClick={() => {
				i18next.changeLanguage(item);
			}}>{languageMap[item].label}
				{isLast && <>&nbsp;&#124;&nbsp;</>}
			</a>
	)
};

export default LinkLng;
