import React from 'react';
import i18next from 'i18next';
import LinkLng from '../linkLng';
import '../linkLng/linkLng.css';

const ToggleLng = () => {
	const languageMap = {
		en: {label: "En", dir: "ltr", active: true},
		uk: {label: "Uk", dir: "rtl", active: false},
		ru: {label: "Ru", dir: "ltr", active: false}
	};
	const activeLng = i18next.language;
	const keys = Object.keys(languageMap);
	
	return (
			keys.map((item, index) => {

				const isLast = index !== keys.length - 1;
				const isActive = languageMap[item].label.toLowerCase() === activeLng;

				return <LinkLng key={index} item={item} index={index} languageMap={languageMap}
									 isLast={isLast} className={isActive ? 'inactive' : ''}/>
			})
	);
};

export default ToggleLng;
