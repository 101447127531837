import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const defaultLng = ['en'];
const collectionLng = ['en', 'uk', 'ru'];

i18n.use(Backend)
		.use(initReactI18next)
		.init({
			detection: {
				checkWhiteList: true,
			},
			debug: true,
			fallbackLng: defaultLng,
			whiteList: collectionLng,
			interpolation: {
				escapeValue: false,
			},
		});

export default i18n;
