import React from 'react';
import './firstSection.css';
import { useTranslation } from 'react-i18next';

export const FirstSection = () => {
  const { t } = useTranslation();

    return(
        <section className='first-section'>
          <div>
            <h1>{t('title_first_section')}</h1>
            <div>
              <h3>{t('subtitle_first_section')}</h3>
            </div>
          </div>
        </section>
    )
};
