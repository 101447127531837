import React from 'react';
import logo from '../../logo.svg';
import { useTranslation } from 'react-i18next';
import './header.css';
import ToggleLng from '../../atoms/toggleLng/index';

const Header = () => {
	const { t } = useTranslation();

	return(
			<header id='top'>
				<img src={logo} alt='logo'/>
				<nav>
					<ul>
						<li><a href='#about-me'>{t('about_me')}</a></li>
						<li><a href='#services'>{t('services')}</a></li>
						<li><a href='#contacts'>{t('contacts')}</a></li>
					</ul>
				</nav>
				<div>
					<ToggleLng />
				</div>
			</header>
	)
};
export default Header;
