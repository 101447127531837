import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';
import arrowUp from '../../images/arrow_up.svg';

export const Footer = () => {
	const { t } = useTranslation();
	return (
			<footer>
				<section className='contacts' id='contacts'>
					<h3>{t('footer_contacts')}</h3>
					<div>
						<a href='tel:+380996378787'>+380996378787</a><br />
						<a href='mailto:ishencko85@gmail.com'>ishencko85@gmail.com</a>
						<div>{t('footer_location')}</div>
					</div>
				</section>
				<div className='footer'>
					<a href='#about-me'>{t('about_me')}</a>
					<a href=''>{t('services')}</a>
					<a href='#contacts'>{t('contacts')}</a>
					<a href='#top' className='back-top'>{t('back_top')}</a>
					<div><img src={arrowUp} alt='back to top arrow'/></div>
				</div>
			</footer>
	)
};
