import React from 'react';
import './thirdSection.css';
import { useTranslation } from 'react-i18next';
import classicMassage from '../../images/classic_massage.png'
import anticelluliteMassage from '../../images/anticellulite_relaxing_massage.png'
import massotherapy from '../../images/massotherapy.png'
import lymphaticMassage from '../../images/lymphatic_drainage_massage.png'
import sportsMassage from '../../images/sports_massage.png'
import relaxingMassage from '../../images/anticellulite_relaxing_massage.png'

function TableElement({title, children, imagePath, reversed = false, alt}) {
    const Text = <section className='massage-group'>
        <h3 className='massage-group-title'>{title}</h3>
        <div className='massage-group-desc'>{children}</div>
    </section>;
    const Image = <img src={imagePath} alt={alt}/>;
    return <td>
        {!reversed
            ?
            <>{Image}{Text}</>
            :
            <>{Text}{Image}</>}
    </td>;
}

export const ThirdSection = () => {
    const {t} = useTranslation();
    return (
        <section className='third-section' id='services'>
            <h1>{t('title_third_section')}</h1>
            <h2>{t('subtitle_third_section')}</h2>
            <h3>{t('subtitle_bold_third_section')}</h3>
            <table className='massage-types'>
                <tbody>
                <tr className='first-row'>
                    <TableElement title={t('classic_massage_title')}
                                  reversed={true}
                                  imagePath={classicMassage}
                                  alt='classic massage'>
                        {t('classic_massage_desc')}
                    </TableElement>
                    <TableElement title={t('anticellulite_massage_title')}
                                  reversed={false}
                                  imagePath={anticelluliteMassage}
                                  alt='anticellulite massage'>
                        {t('anticellulite_massage_desc')}
                    </TableElement>
                    <TableElement title={t('massotherapy_title')}
                                  reversed={true}
                                  imagePath={massotherapy}
                                  alt='massotherapy' >
                        {t('massotherapy_desc')}
                    </TableElement>
                </tr>
                <tr className='second-row'>
                    <TableElement title={t('lymphatic_drainage_massage_title')}
                                  reversed={true}
                                  imagePath={lymphaticMassage}
                                  alt='lymphatic drainage massage'>
                        {t('lymphatic_drainage_massage_desc')}
                    </TableElement>
                    <TableElement title={t('sports_massage_title')}
                                  reversed={false}
                                  imagePath={sportsMassage}
                                  alt='sports massage'>
                        {t('sports_massage_desc')}
                    </TableElement>
                    <TableElement title={t('relaxing_massage_title')}
                                  reversed={true}
                                  imagePath={relaxingMassage}
                                  alt='relaxing massage' >
                        {t('relaxing_massage_desc')}
                    </TableElement>
                </tr>
                </tbody>
            </table>
        </section>
    )
}
