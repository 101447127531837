import React from 'react';
import { useTranslation } from 'react-i18next';
import './secondSection.css';
import portrait from '../../images/portrait.png';
import massageIcon from '../../images/massage_icon.svg';
import resultIcon from '../../images/result_icon.svg';
import guaranteeIcon from '../../images/guarantee_icon.svg';

export const SecondSection = () => {
    const { t } = useTranslation();

    return (
        <>
          <section className='second-screen' id='about-me'>
              <picture>
                  <img src={portrait} alt='portrait'/>
              </picture>

              <div>
                  <h2>{t('name')}</h2>
                  <h5>{t('experience')}</h5>
                  <div />
                  <h6 dangerouslySetInnerHTML={
                      {__html: t('description_experience', {interpolation: {escapeValue: false}})}
                  }/>
              </div>
          </section>
          <section className='icon-section'>
              <div>
                <img src={massageIcon} alt='massageIcon'/>
                <div className='title-icon' dangerouslySetInnerHTML={
                  {__html: t('title_massage_icon', {interpolation: {escapeValue: false}})}
                } />
              </div>
              <div>
                <img src={resultIcon} alt='resultIcon'/>
                <div className='title-icon' dangerouslySetInnerHTML={
                  {__html: t('title_result_icon', {interpolation: {escapeValue: false}})}
                } />
              </div>
              <div>
                <img src={guaranteeIcon} alt='guaranteeIcon'/>
                <div className='title-icon' dangerouslySetInnerHTML={
                  {__html: t('title_guarantee_icon', {interpolation: {escapeValue: false}})}
                } />
              </div>
          </section>
          <section className='relax-bg'>
              <div className='custom-hr top'/>
              <div className='title'>{t('relax_title')}</div>
              <div className='custom-hr bottom'/>
          </section>
        </>
    )
};
